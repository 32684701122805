import React from 'react';
import { Heading } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { shape, string, arrayOf } from 'prop-types';



import css from './ListingPage.module.css';

/**
 * Renders the section menu component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.publicData - The public data object.
 * @returns {JSX.Element|null} The rendered section menu component.
 */
const SectionMenu = props => {
  
  const { publicData } = props;

  const menu = publicData?.menu;
  const hasMenu = menu?.length > 0;




  return hasMenu ? (
    <div className={css.sectionMenu}>
          <Heading as="h4">
          <FormattedMessage id="ListingPage.menuTitle" />
        </Heading>
      {menu.map((menu, index) => {
        return (
          <div key={menu.key || index} className={css.innerdiv}>
            <Heading as="h2"rootClassName={css.sectionHeadingWithExtraMargin}> {menu.coursename} </Heading>
            <p>{menu.coursedescription}</p>
          </div>
        );
      })}
    </div>
  ) : null;
};



SectionMenu.propTypes = {

  publicData: shape({ 
    menucourse: string,
    menudescription: string,
  
  }),
};

export default SectionMenu;


